import React from "react";
import "./terms.scss";
import Layout from "../components/Layout";

const LegalPage = () => {
  return (
    <Layout
      onScrollToHero={() => (window.location.href = "https://zen-code.ro")}
      onScrollToWork={() => {}}
      onScrollToAbout={() => {}}
      onScrollToService={() => {}}
      onScrollToContact={() => {}}
      pageTitle="Zen code - Terms & privacy policy"
      showNav={false}
    >
      <div className="terms">
        <h2 id="Terms">Terms of Service</h2>
        <h3>1. Subject and Scope</h3>
        <p>
          <span>1.1</span>
          Zencode applications for Android and iOS (“apps”), websites
          (“websites”), and related services (together with the apps and
          websites, the “services”) are operated by Zen code software enterprise
          SRL, I C Bratianu 100, bloc SR2, ap 26, Constanta, Romania (thereafter
          Zencode,” “us,” or “we”).
        </p>
        <p>
          <span>1.2</span>
          Your use of the services is subject to the following terms and
          conditions.
        </p>
        <p>
          <span>1.3</span>
          By accessing or using any part of the services, you accept the
          application of these terms and conditions.
        </p>
        <p>
          <span>1.4</span>
          The content of the contract concluded between Zencode and you follows
          these terms and conditions as well as the specific information
          provided as part of the registration and by entering into a
          subscription or trial.
        </p>
        <p>
          <span>1.5</span>
          Zencode may amend, change, or update these terms and conditions. If
          Zencode does not obtain your specific consent for any revisions of its
          terms and conditions, you will be notified at least seven days prior
          to the effective date of the updated terms and conditions.
        </p>
        <p>
          <span>1.6</span>
          Any revisions to the terms and conditions will become effective the
          earlier of the end of the seven-day period or the first time you
          access or use the services after such revisions.
        </p>
        <p>
          <span>1.7</span>
          If you do not agree to the revisions, you may not be able to continue
          to access or use the services from the effective date of the updated
          terms and conditions, if providing the services under the scope of the
          current terms and conditions is not possible or unreasonable for
          Zencode.
        </p>
        <h3>2. Services and Rights</h3>
        <p>
          <span>2.1</span>
          The services allow you to access and use a variety of educational
          content and services on computer science, data science, programming,
          software development, and related topics.
        </p>
        <p>
          <span>2.2</span>
          The scope of the services may depend on the type of account,
          subscription, or trial you have.
        </p>
        <p>
          <span>2.3</span>
          The services are provided to you as a limited, non-exclusive,
          non-sublicensable, non-transferable basis for your private, personal,
          non-commercial license to download, install and use, if applicable, a
          copy of the apps in object code form on a compatible device in your
          control or ownership.
        </p>
        <p>
          <span>2.4</span>
          All content available through the services, including audio, designs,
          graphics, images, information, software, text, and other files as well
          as their arrangement and selection are the proprietary property of
          Zencode or its licensors. You may not copy, distribute, display,
          download, post, reproduce, republish, scrape, sell, or transmit, in
          any form or by any means, in whole or in part, other than as expressly
          permitted in these terms and conditions.
        </p>
        <p>
          <span>2.5</span>
          "Zencode" and all other trademarks, service marks, graphics, and logos
          used in connection with the services are trademarks or service marks
          of Zencode or their respective owners. Access or use of the services
          does not grant or provide you with the right or license to reproduce
          or otherwise use Zencode trademarks, service marks, graphics, and
          logos.
        </p>
        <p>
          <span>2.6</span>
          You may not derive or attempt to derive the source code of any part of
          the services, permit any third party to derive or attempt to derive
          such source code, or decompile, disassemble, reverse engineer, or
          translate the services or any part thereof. Zencode and its licensors
          own and shall retain all intellectual property rights and other rights
          in and to the services and any changes, modifications, or corrections
          thereto.
        </p>
        <p>
          <span>2.7</span>
          You represent and warrant that your access and use of the service is
          in accordance with these terms and conditions and all applicable laws,
          rules, and regulations of Romania and any other relevant jurisdiction.
        </p>
        <p>
          <span>2.8</span>
          Zencode may change, update, suspend, make improvements to, or
          discontinue any aspects of the services.
        </p>
        <h3>3. Account</h3>
        <p>
          <span>3.1</span>
          To access and use the services, you are required to register, i.e.
          create an account.
        </p>
        <p>
          <span>3.2</span>
          You may use your email address along with a self-determined password
          or a single sign-on application provided by third parties such as
          Apple, Facebook, or Google to create an account.
        </p>
        <p>
          <span>3.3</span>
          You are required to maintain the confidentiality of your password and
          other information related to the security of your account.
        </p>
        <p>
          <span>3.4</span>
          You agree to provide accurate, complete, and current information about
          you as requested by Zencode.
        </p>
        <p>
          <span>3.5</span>
          To create an account, you are required to be at least eighteen years
          of age. If you are at least fourteen years of age, you are allowed to
          register create an account with consent of your statutory agents.
          Zencode has the right to make its services subject to appropriate
          proof of identity or consent of your statutory agents.
        </p>
        <h3>4. Content</h3>
        <p>
          <span>4.1</span>
          By creating, storing, or submitting any images, information, reviews,
          source code, text, translations, or other materials through the
          services, you grant Zencode an irrevocable, non-exclusive, perpetual,
          royalty-free, transferable, and worldwide license to copy, create
          derivative works from, display, distribute, excerpt, reformat, reuse,
          and translate such content for providing the services as well as
          personalizing your experience of and promoting the services and you
          acknowledge that you cannot terminate this license after creating,
          storing, or submitting the content.
        </p>
        <p>
          <span>4.2</span>
          You are responsible for the content you create, store, or submit and
          represent that you own or have the necessary legal rights for the
          content to be used by you through the services.
        </p>
        <p>
          <span>4.3</span>
          You warrant that you will not create, store, submit, or otherwise
          provide on or through the service any content that
        </p>
        <ul>
          <li>
            is abusive, harassing, hateful, offensive, threatening, or otherwise
            violates any law or infringes upon the rights of any third party
            (including copyright, privacy, publicity, trademark, or other
            personal or proprietary rights);
          </li>
          <li>
            in Zencode's sole judgment, is explicit or objectionable, damages,
            exploits, or otherwise impairs the services in any way;
          </li>
          <li>
            or may expose Zencode or any third party to harm or liabilities of
            any kind.
          </li>
        </ul>
        <p>
          <span>4.4</span>
          Zencode monitors content created and stored through the services and
          reserves the right to remove any content that is considered
          inappropriate.
        </p>
        <h3>5. Subscription</h3>
        <p>
          <span>5.1</span>
          Zencode offers automatically renewable subscriptions (“subscriptions”)
          for a variety of periods (“subscription periods”).
        </p>
        <p>
          <span>5.2</span>A subscription enables you to access and use the full
          scope of the services for the subscription period.
        </p>
        <p>
          <span>5.3</span>
          To enter into a subscription through the websites, you are required to
          choose a subscription period on the websites, provide your credit card
          information, and confirm your purchase, upon which you will receive a
          receipt via email.
        </p>
        <p>
          <span>5.4</span>
          To enter into a subscription through the apps (“in-app subscription”),
          you can choose a subscription period within the apps and confirm your
          purchase, upon which you will receive a receipt via email. In-app
          subscriptions use the in-app purchase functionality of the App Store
          on iOS or Google Play on Android (“app stores”).
        </p>
        <h3>6. Trials</h3>
        <p>
          <span>6.1</span>
          Zencode may occasionally offer you to access and use the full scope of
          the services for trial purposes (“trials”) for a variety of periods
          (“trial periods”).
        </p>
        <p>
          <span>6.2</span>
          Zencode may, in its sole discretion, determine your eligibility for a
          trial and suspend or discontinue trials at any time.
        </p>
        <p>
          <span>6.3</span>
          To start a trial, you may be required to provide your credit card
          information on the websites or use the in-app trial functionality of
          the app stores within the apps and confirm your trial.
        </p>
        <p>
          <span>6.4</span>
          Your trial may convert into a subscription after the trial period. In
          such a case, the subscription period will be part of the information
          provided in the trial offer.
        </p>
        <p>
          <span>6.5</span>
          To prevent a trial from converting into a subscription, you may
          terminate the trial within the trial period on the websites or within
          the app stores.
        </p>
        <h3>7. Promotional Codes</h3>
        <p>
          <span>7.1</span>
          Zencode may occasionally offer you promotional codes to purchase
          subscriptions for a variety of subscription periods at a reduced fee
          (“promotional codes”).
        </p>
        <p>
          <span>7.2</span>
          Zencode may, in its sole discretion, determine your eligibility for a
          promotional code and suspend or discontinue codes at any time.
        </p>
        <p>
          <span>7.3</span>
          Codes cannot be redeemed for cash, refunded, exchanged, or used for
          other purchases than stipulated in the offer.
        </p>
        <p>
          <span>7.4</span>
          Specific provisions relating to the code may apply in addition to
          these terms and conditions. If these provisions stipulate a period of
          validity, the reduced fee shall only apply for this period and
          increase to the regular fee upon expiration of the period of validity.
        </p>
        <h3>8. Revocation</h3>
        <p>
          <span>8.1</span>
          If you have purchased a subscription or other services subject to
          charges on the websites as a consumer, you have the right to revoke
          this contract within fourteen days without the need to specify
          reasons.
        </p>
        <p>
          <span>8.2</span>
          This revocation right will exist for a period of fourteen days from
          the day of the purchase.
        </p>
        <p>
          <span>8.3</span>
          To exercise your revocation right, you are required to notify Zen code
          software enterprise SRL, I C Bratianu 100, bloc SR2, ap 26, Constanta,
          Romania or support@zen-code.ro using an explicit statement regarding
          your decision to revoke the contract (“revocation notification”).
        </p>
        <p>
          <span>8.4</span>
          Dispatch of the revocation notification before the revocation period
          expires is sufficient to meet the revocation deadline.
        </p>
        <h3>Consequences of Revocation</h3>
        <p>
          If you revoke this contract, Zencode will refund your purchase by no
          later than fourteen days from the day of receipt of the revocation
          notification using the same payment method for the refund that you
          have used for the original transaction, unless a deviating method has
          been stipulated with you.
        </p>
        <h3>Revocation Notification Template</h3>
        <p>
          If you wish to revoke the contract, please complete and return the
          following form to Zen code software enterprise SRL, I C Bratianu 100,
          bloc SR2, ap 26, Constanta, Romania or support@zen-code.ro:
        </p>
        <p>
          I hereby revoke the contract concluded by me regarding the performance
          of the following service:
        </p>
        <br />
        <p>Ordered on:</p> <p>Name: </p>
        <p>Address: </p>
        <p>Signature (if provided on paper) </p>
        <p>Date:</p>
        <h3>9. Payments</h3>
        <p>
          <span>9.1</span>
          The conclusion of a subscription is subject to charges (“payment”).
        </p>
        <p>
          <span>9.2</span>
          If you have purchased a subscription on the websites, payment will be
          made using the credit card information you provided upon entering into
          the subscription.
        </p>
        <p>
          <span>9.3</span>
          If you have purchased the subscription within the apps, payment will
          be made through the in-app purchase functionality as provided by the
          app stores.
        </p>
        <p>
          <span>9.4</span>
          Your payment obligation will renew alongside your subscription at the
          end of the subscription period, unless you terminate your subscription
          at least twenty-four hours prior to the end of the subscription
          period.
        </p>
        <h3>10. Termination</h3>
        <p>
          <span>10.1</span>
          The services start at the time of registration pursuant for an
          indefinite period.
        </p>
        <p>
          <span>10.2</span>
          You can terminate the services at any time. For this purpose, you are
          required to delete your account in your account settings on the
          websites.
        </p>
        <p>
          <span>10.3</span>
          The subscription will be active for the subscription period selected
          at the time of purchase and will automatically renew unless terminated
          at least twenty-four hours prior to the expiration of the period. The
          subscription period shall extend by the same period as the period of
          the active subscription.
        </p>
        <p>
          <span>10.4</span>
          You can terminate a subscription you have purchased on the websites in
          your account settings on those websites.
        </p>
        <p>
          <span>10.5</span>
          You can terminate an in-app subscription within the app stores.
        </p>
        <p>
          <span>10.6</span>A termination of the subscription will become
          effective the day after the last day of the subscription period and
          your account will be converted to an account.
        </p>
        <p>
          <span>10.7</span>
          Upon reentering into a subscription, you will be able to access and
          use the full scope of the services and retain any previous content or
          progress.
        </p>
        <p>
          <span>10.8</span>
          If you intend to delete your account, you can do so in your account
          settings on the websites. In such a case, your account and personal
          information will be irrevocably deleted, regardless of whether there
          are any active subscriptions.
        </p>
        <h3>11. Limitation on Types of Damages and Liability</h3>
        <p>
          <span>11.1</span>
          Zencode is not liable for user damage claims and only liable for
          damages arising from body, life, or health violations or from
          violations of material contractual duties as well as for damages for
          other damages arising from intentional and gross negligent breach of
          duty of the company or its statutory or vicarious agents.
        </p>
        <p>
          <span>11.2</span>
          In the event of violations of material contractual duties, Zencode is
          only liable for the contractual and typically foreseeable damages if
          these were caused by slight negligence, unless damages arising from
          injury to life, body, or health are present.
        </p>
        <p>
          <span>11.3</span>
          The restrictions stipulated under ciphers 10.1 and 10.2 also apply for
          duty violations of statutory or vicarious agents of Zencode.
        </p>
        <h3>12. Privacy</h3>
        <p>
          <span>12.1</span>
          Your use of the services is governed by a{" "}
          <a href="#privacy-policy">Privacy Policy</a>. By using the services,
          you consent to the terms of the Privacy Policy.
        </p>
        <h3>13. Miscellaneous</h3>
        <p>
          <span>13.1</span>
          If one provision of these terms and conditions shall be deemed as
          void, the remainder of the terms and conditions shall remain in full
          force. If applicable, the void provision shall be replaced by the
          statutory provisions.
        </p>
        <p>
          <span>13.2</span>
          The laws of the Romania shall apply, excluding the United Nations
          Convention on the International Sale of Goods (CISG). Statutory
          provisions (especially consumer protection provisions) as legally
          required by your country of residence shall remain unaffected.
        </p>
        <p>
          <span>13.3</span>
          The exclusive place of jurisdiction for all disputes, directly or
          indirectly resulting from the contractual relationship, shall be the
          respective court at Zencode's registered seat of business if you are
          an entrepreneur, a legal entity of public law, a special public fund,
          or have no general place of jurisdiction within the Romania or another
          member state of the European Union or relocated your
          residence/business seat after application of these terms abroad and
          outside the European Union. These terms and conditions were last
          modified on Jan 24, 2020.
        </p>
        <h2 id="privacy-policy">Privacy Policy</h2>
        <h3>1. General</h3>
        <p>
          <span>1.1</span>
          At Zencode, we know how much you care about your personal information,
          so we have prepared this privacy policy to explain how we collect,
          use, and share it with third parties.
        </p>
        <p>
          <span>1.2</span>
          We want to show you that we handle your personal information in a
          confidential and responsible way and that any processing of your
          personal information happens in compliance with the General Data
          Protection Regulation ("GDPR") as well as local information privacy
          laws such as the Romanian data protection act ("RGPD") and the
          California Consumer Privacy Act ("CCPA").
        </p>
        <p>
          <span>1.3</span>
          To avoid unauthorized access to your personal data and generally
          secure such data, we use encrypted transmission and encrypted storage.
          Those safety measures are constantly revised to comply with the latest
          technological developments.
        </p>
        <p>
          <span>1.4</span>
          We may update our privacy policy to reflect changes to our information
          practices. If we do this and the changes are material, we will post a
          notice that we have made changes to this privacy policy on the
          Websites for at least 7 days after the changes are made, ask for your
          consent, and indicate the date these terms were last revised at the
          bottom of the privacy policy. Any revisions to this privacy policy
          will become effective the earlier of (i) the end of that 7-day period
          or (ii) the first time you access or use the Services after any such
          changes.
        </p>
        <h3>2. Information we collect</h3>
        <p>
          When you register for, access, or use the Services, we process certain
          of your personal information (“Data” or “Information”).
        </p>
        <p>
          <span>2.1</span>
          If you visit the Websites, we process only Data that your browser
          communicates to our servers. We collect the following Data, which is
          necessary for us in order to display the Website correctly and
          guarantee the necessary stability and safety:
        </p>
        <ul>
          <li>IP address;</li>
          <li>date and time stamp;</li>
          <li>time difference to GMT;</li>
          <li>requested site; </li>
          <li>access status/HTTP</li>
          <li>status code;</li>
          <li>transmitted data volume;</li>
          <li>site from which the request was sent; browser; </li>
          <li>operating system and interface; </li>
          <li> and language and version of the browser software.</li>
        </ul>
        <p>
          <span>2.2</span>
          Depending on your choice of registration, we process the following
          Data:
        </p>
        <p>Zencode:</p>
        <ul>
          <li>email address and full name.</li>
        </ul>
        <p>Apple single sign-on application:</p>
        <ul>
          <li> email address and full name (unless hidden)</li>
          <li> language, country, time zone;</li>
          <li> devices, browser, IP address;</li>
        </ul>
        <p>
          <span>2.3</span>
          When you make purchases in the Services, we process the following
          Data:
        </p>
        <ul>
          <li>country;</li>
          <li>credit card information;</li>
          <li>subscription status.</li>
        </ul>
        <p>
          <span>2.4</span>
          When you use the Services, we process the following Data:
        </p>
        <ul>
          <li>
            when you launch, signup to, log into, and log out of the Services;
          </li>
          <li>when you purchase something in the Services;</li>
          <li>what kind of tracks, courses, and exercises you complete.</li>
        </ul>
        <h3>3. Use of Information</h3>
        <p>
          <span>3.1</span>
          The processing of Data pursues the following purposes ("Purposes"):
        </p>
        <ul>
          <li>providing the Services;</li>
          <li>customer relations management (e.g. support);</li>
          <li>marketing for our own products (newsletters, push messages);</li>
          <li>personalizing your experience of the Services;</li>
          <li>research and development;</li>
          <li>communicating with you about the Services;</li>
          <li>market, promote and drive engagement with the Services; </li>
          <li>ensuring safety and security;</li>
          <li>
            and protecting our legitimate business interests and legal rights.
          </li>
        </ul>
        <p>
          <span>3.2</span>
          The lawfulness of processing (Art. 6 GDPR) stems from
        </p>
        <ul>
          <li>
            the consent pursuant para. 1 subpara. a GDPR upon registration;{" "}
          </li>
          <li>
            the necessity for the performance of contract fulfillment pursuant
            para 1 subpara. b GDPR, as your Data is needed for a satisfactory
            use of the Services;
          </li>
          <li>
            and/or the necessity for the purposes of the legitimate interests
            pursued by the Company or by a third party.
          </li>
        </ul>
        <h3>4. Updating or deleting Information</h3>
        <p>
          <span>4.1</span>
          We store your Data as long as you are a registered user of the
          Services. Where we retain information for Service improvement and
          development during your membership, we take steps to eliminate
          information that directly identifies you and we only use the
          information to uncover collective insights about the use of our
          Services, not to specifically analyze personal details about you.
        </p>
        <p>
          <span>4.2</span>
          After you have deleted your account we only store Data if it is
          legally necessary (because of warranty, limitation or retention
          periods) or otherwise required.
        </p>
        <p>
          <span>4.3</span>
          Data will be deleted if you (a) revoke your consent to the storage (b)
          Data is not needed to fulfill the user contract concerning the
          Services anymore, or (c) the storage is or becomes legally
          impermissible. A deletion request does not affect Data, if the storage
          is legally necessary, for example for accounting purposes.
        </p>
        <h3>5. Rights</h3>
        <p>
          <span>5.1</span>
          To exercise the rights defined in Section 5.2 to 5.8, please send a
          request via email to the Email Address or via letter mail to the
          address provided in the legal notice on the Website
          (https://zen-code.ro/legal).
        </p>
        <p>
          <span>5.2</span>
          You can revoke the consent for future data processing at any time.
          However, this does not affect the lawfulness of Data processing based
          on the consent before the revocation. Should the processing of Data be
          lawful due to another reason stated in 4.2, we may continue to process
          your Data on these grounds.
        </p>
        <p>
          <span>5.3</span>
          You have the right to obtain (i) confirmation as to whether or not
          your Data is being processed by us and, if so, (ii) more specific
          information on the Data. The more specific information concerns, among
          others, processing purposes, categories of Data, potential recipients
          or the duration of storage.
        </p>
        <p>
          <span>5.4</span>
          You have the right to obtain from us the rectification of inaccurate
          Data concerning you. In case the Data processed by us is not correct,
          we will rectify these without undue delay and inform you of this
          rectification.
        </p>
        <p>
          <span>5.5</span>
          If you decide you do not want us to process your data any further,
          please send a request via email to the Email Address or via letter
          mail to the address provided in the legal notice on the Website
          (https://zen-code.ro/legal). We will erase your Data immediately and
          inform you of this process. Should mandatory provisions of law prevent
          such erasure, we will inform you without undue delay thereof.
        </p>
        <p>
          <span>5.6</span>
          You have the right to obtain from us a restriction of processing of
          your Data in the following cases:
        </p>
        <ul>
          <li> You make an inquiry pursuant para. 7.4, if you so request;</li>
          <li>
            you are of the opinion, that the processing of your Data is
            unlawful, but are opposed to an erasure of Data;
          </li>
          <li>
            you still require the Data for the establishment, exercise or
            defense of legal claims;
          </li>
          <li> or you have objected to the processing pursuant para. 7.8.</li>
        </ul>
        <p>
          <span>5.7</span>
          You have the right to (i) receive your Data in a structured, commonly
          used and machine-readable format and (ii) transmit those Data to
          another controller without hindrance from us.
        </p>
        <p>
          <span>5.8</span>
          You have the right to object at any time to the processing of Data
          based on our legitimate interests, including profiling and direct
          marketing purposes.
        </p>
        <p>
          <span>5.9</span>
          You have the right to lodge a complaint with a supervisory authority
          if you think that the processing of Data infringes applicable law,
          especially the GDPR.
        </p>
        <h3>6. Cookies</h3>
        <p>
          <span>6.1</span>
          The Websites may use cookies, small text files that are placed on your
          computer, smartphone and/or stored by the browser. If the respective
          server of our Website is again accessed by you, your browser sends the
          afore received cookie back to the server. The server can evaluate the
          information received in this manner in various ways. Cookies can, for
          example, be used in order to manage advertisements on the Website or
          to facilitate navigation on a webpage.
        </p>
        <p>
          <span>6.2</span>
          You can disable the cookies by entering the corresponding settings in
          your browser software (e.g. in Internet Explorer, Mozilla Firefox,
          Opera, or Safari). However, in this case you may jeopardize the use of
          the complete range of functions on the Websites.
        </p>
        <p>
          <span>6.3</span>
          For additional information, please see our cookie policy.
        </p>
        <h3>7. Analytics</h3>
        <p>
          <span>7.1</span> Google Analytics General. For the Services, we use
          Google Analytics for Firebase ("Firebase"), a measurement solution
          that provides insight on app usage and user engagement. Opt-Out. You
          can opt out of certain Firebase features, e.g. mobile device
          identification, by changing the appropriate settings on your mobile
          devices. Purpose. We use Firebase to analyze the use of and improve
          the Services. Based on Firebase reports, we can identify friction
          points in the Services and measure how updates to the Services affect
          overall user behavior. Firebase is provided by Google Ireland Limited,
          Gordon House, 4 Barrow St, Dublin 4, Ireland. For exceptional cases in
          which personal data is transferred to the US, Google is certified via
          the EU-US privacy shield. The legal basis for processing is Art 6 para
          1 subpara f GDPR. The Firebase privacy policy can be found at
          https://www.firebase.com/terms/.
        </p>
        <p>
          <span>7.2</span> Facebook Analytics General. For the Services and
          marketing optimization purposes, we use Facebook Analytics
          ("Facebook"). Opt-Out. You can opt out of certain Facebook features,
          e.g. mobile device identification, by changing the appropriate
          settings on your mobile devices. Purpose. We use Facebook to measure
          the performance of marketing campaigns and creatives. Based on
          Facebook reports, we can distinguish between profitable and
          unprofitable marketing campaigns and creatives. For more information
          on Facebook, see https://developers.facebook.com/docs/analytics. For
          cases in which personal data is transferred to the US, Facebook is
          certified via the EU-US privacy shield. The legal basis for the
          processing of Data is Art 6 sec 1 subsec f GDPA.
        </p>
        <h3>8. Contact</h3>
        <p>
          <span>8.1</span>
          We are acting as the controller for the data processing within the
          meaning of the GDPR.
        </p>
        <p>
          <span>8.2</span>
          To understand more about the privacy policy, access your personal
          information, ask questions about our privacy practices, or issue a
          complaint, please contact our data protection officer within the
          meaning of the GDPR at privacy@zen-code.ro. This privacy policy was
          last modified on Nov 25, 2022.
        </p>
      </div>
    </Layout>
  );
};

export default LegalPage;
